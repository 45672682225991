import React from 'react';
import { CtaFooter, CtaHeader, CardBlog, CardBlogMain, SectionHero } from '../component';
import { useFetch } from '../hooks'

const Blogs = () => {

  const {loading, data, error} = useFetch('https://itb-backend-91cf7f321679.herokuapp.com/api/blogs?populate=*')

  return (
    <div className="common-wrapper">
      
      <SectionHero  text="BLOGS" />

      <div className='common-container'>

        <CtaHeader
          heading="Our Recent Blogs"
          text="Stay informed and inspired with our latest insights and stories in our recent blogs!"
        />

        <section className="blogs-grid-wrapper">
          {!loading && <CardBlogMain blog={data.data.slice(-1)[0]} />}
          
          <div className="blogs-grid">
            {!loading && data.data.slice(0,-1).reverse().map((blog, i) => (
              <CardBlog key={i} blog={blog} />
            ))}
          </div>
        </section>

        <CtaFooter />
      </div>
    </div>
  );
};

export default Blogs;
