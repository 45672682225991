import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { 
  MainPage, AboutUsPage,
  ServiceMainPage, ServiceWritePage, BlogMainPage, BlogWritePage,
  ContactUsPage, OurPartnersPage,
  NotFoundPage, ComingSoonPage 
} from './pages'; 
import { Navbar, Footer } from './component'

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/about" element={<AboutUsPage />} />

          <Route path="/blogs" element={<BlogMainPage />} />
          <Route path="/blogs/:slug" element={<BlogWritePage />} />
          <Route path="/services" element={<ServiceMainPage />} />
          <Route path="/services/:slug" element={<ServiceWritePage />} />

          <Route path="/partners" element={<OurPartnersPage />} />
          <Route path="/career" element={<ComingSoonPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="*" element={<NotFoundPage />} />
          </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;