import Markdown from "markdown-to-jsx"
import { useEffect, useState } from "react"
import { useParams, useNavigate } from 'react-router-dom';
import { blogSlug } from '../content'
import { CtaFooter, SectionHero } from '../component'
import { useFetch } from '../hooks'

const Post = () => {

    const {loading, data, error} = useFetch('https://itb-backend-91cf7f321679.herokuapp.com/api/blogs?populate=*')
  
    const { slug } = useParams();

    const [blog, setBlog] = useState(null);

    useEffect(() => {
        if (data && data.data) {
            const _blog = data.data.filter(blog => blog.documentId === slug);
            setBlog(_blog[0] || null);
        }
    }, [data, slug]);
    

    const navigate = useNavigate();

    const onClickBlogs = () => {
        navigate('/blogs');
        window.scrollTo(0, 0);
    }

    return (
        <div id="markdown-wrapper" className="common-wrapper">
            
            <SectionHero  text="BLOGS" />

            {blog && <div className="markdown-container">
                {<img className="markdown-image" src={`https://itb-backend-91cf7f321679.herokuapp.com${blog.image[0].url}`} alt="logo main" />}
                <div className="markdown-content">
                    <Markdown>
                        {blog && blog.content}
                    </Markdown>
                </div>
            </div>}

            <div className="markdown-btn-container">
                <button onClick={onClickBlogs} className="markdown-btn">GO BACK TO BLOGS</button>
            </div>

            <div className="common-container">
                <CtaFooter />
            </div>
        </div>
    )
}

export default Post