import React from 'react'
import { useNavigate } from 'react-router-dom';

const CardBlog = ({blog}) => {

  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(blog.documentId);
    window.scrollTo(0, 0);
  };

  return (
    <div className="blog-card-main">
      <img className="blog-card__image" src={`https://itb-backend-91cf7f321679.herokuapp.com${blog.image[0].url}`} alt={blog.title} />
      <div className="blog-card__content">
        <div>
          <h3 className="blog-card__title">{blog.title}</h3>
          <p className="blog-card__text">{blog.description}</p>
        </div>
        <button className="blog-card-main__button" onClick={handleCardClick}>READ MORE</button>
      </div>
    </div>
  )
}

export default CardBlog
